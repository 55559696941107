import React from 'react';
import './refund.scss';

function Refund() {
    return (
        <div className="privacy-container">
            <h1>Refund Policy</h1>

            <p>
                This Refund Policy is an integral part of the Terms and Conditions. By agreeing to the Terms and Conditions, you also agree to the Refund Policy.
            </p>

            <p>
                Our website located at https://flagency.co/ (hereinafter referred to as the "Website") provides an online platform facilitating the matching of persons offering to create promotional online media content.
            </p>

            <p>
                LeadeFactory pays money to the bloggers in a variety of ways including Bank transfers, credit card, debit card, PayPal, and Bank wire.
            </p>

            <p>
                You agree that LeadFactory is not responsible for any actions applied by the payment service provider, including but not limited to any additional transaction fees, banking commissions, or currency fees applied to your transaction. Leadfactory does not store credit/debit card information.
            </p>

            <h2>Refunds</h2>
            <ol>
                <li>All the details of cooperation between bloggers and LeadFactory are in the Agreement, which includes Terms and Conditions and IO.</li>
                <li>Terms of payment between bloggers and LeadFactory are in the Agreement.</li>
                <li>
                    If the collaboration with a blogger is on a prepayment (full or partial) and they refuse to perform work or don't fulfill it in due time, the blogger is obliged to pay back the funds to LeadFactory within 5 (Five) working days from the moment of waiver of the work or the expiry of the period of performance.
                </li>
                <li>
                    If the blogger does not return the funds within the specified period, the company reserves the right to apply to the court for the recovery of these funds, and the blogger undertakes to reimburse all legal costs, including attorneys' fees, in full.
                </li>
                <li>Terms of payment, blogger penalty, or fine in case of refusal to cooperate with him are specified in the Agreement between bloggers and LeadFactory.</li>
            </ol>

            <h2>Contact Us</h2>
            <p>
                If you have any questions on how to return your item to us, contact us.
            </p>
            <p>
                Company: Lead Factory LLC<br />
                Address: 30 N Gould St Ste R, Sheridan, WY 82801<br />
                E-mail: Support@flagency.com
            </p>
        </div>
    );
}

export default Refund;
