import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './HeroSection.scss';

interface HeroSectionProps {
    underTitle: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({ underTitle }) => {
    const [email, setEmail] = useState('');
    const [messageStatus, setMessageStatus] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setMessageStatus('Submitting...'); // Set status to 'Submitting...' when form is submitted

        // use EmailJS to send the email
        emailjs.send(
            'service_u6gkxgq',
            'template_rhcah4d',
            { email: email },
            'PjsPhpXXqUkRqeEC8'
        )
            .then((result) => {
                console.log(result.text);
                setMessageStatus('Email sent successfully!');
            }, (error) => {
                console.log(error.text);
                setMessageStatus('An error occurred. Please try again.');
            });
        setEmail(''); // Clear the input field
    };

    return (
        <div className="hero-section">
            <div className="hero-content">
                <h1 className="hero-title">
                    We provide you with the best{' '}
                    <span>lead acquisition</span> team.
                </h1>
                <h3>{underTitle}</h3>
            </div>
            <form className="hero-under-content" onSubmit={handleSubmit}>
                <input
                    type="email"
                    id="email"
                    placeholder="Your email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <button className="button-input" type="submit">Book a call</button>
            </form>
            <div className="message-status">{messageStatus}</div>
        </div>
    );
};

export default HeroSection;
