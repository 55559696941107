
import React from 'react';
import './NotFound.scss';

const NotFoundPage = () => (
    <div className='not-found-con'>
        <h1>404</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
    </div>
);

export default NotFoundPage;
