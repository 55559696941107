import React from 'react';
import { motion } from 'framer-motion';
import './People.scss';
import youtubeLogo from '../../assets/yt.svg'; // Replace this with the path to your YouTube logo

interface PeopleSectionProps {
    people: { name: string; image: string; description: string }[];
}

const PeopleSection: React.FC<PeopleSectionProps> = ({ people }) => {
    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <div className="people-section">
            {people.map((person, index) => (
                <motion.div
                    key={index}
                    className="person"
                    variants={cardVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.5, delay: index * 0.2 }}
                >
                    <img src={person.image} alt={person.name} />
                    <h3>{person.name}</h3>
                    <p>{person.description}</p>
                </motion.div>
            ))}
            <div className="youtube-verified">
                <img src={youtubeLogo} alt="YouTube logo" />
                <p>Every team memeber has passed YouTube Certified programme</p>
            </div>
        </div>
    );
};

export default PeopleSection;
