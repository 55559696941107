import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Title from '../../components/Title/Title';
import People from '../../components/People/People';
import Achievements from '../../components/Achievements/Achievements';
import Values from '../../components/Values/Values';
import FooterSection from '../../components/FooterSection/FooterSection';
import justin from '../../assets/justing.jpg';
import rob from '../../assets/rob.jpg';
import miguelina from '../../assets/Miguelina.jpg';
import logo from '../../assets/logo.png';

const AboutUs: React.FC = () => {
    const navbarSectionProps = {
        title: 'CryptoG',
        logo: logo,
        links: [
            { label: 'HOME', url: '/' },
            { label: 'ABOUT US', url: '/about' },
            { label: 'CONTACT', url: '/contact' },
        ],
    };

    const TitleSectionProps = {
        title: 'Who are we ?',
        underTitle: 'Get to know with our team!',
    };

    const PeopleSectionProps = {
        people: [
            {
                name: 'Justing Schulz',
                image: justin,
                description: 'Head of IM',
            },
            {
                name: 'Rob Farmer',
                image: rob,
                description: 'Chief Execute Officer',
            },
            {
                name: 'Miguelina Essex',
                image: miguelina,
                description: 'Head of affiliate marketing',
            },
        ],
    };

    const ValuesProps = {
        values: [
            {
                title: "Commitment to Excellence",
                description: 'At LeadFactory, we are committed to providing our clients with the highest quality leads. We leverage cutting-edge technology and data analysis to ensure that our leads are qualified, relevant, and have a high potential to convert. We believe that our success is directly tied to the success of our clients, and we are dedicated to exceeding expectations in every engagement.'
            },
            {
                title: "Innovation and Adaptability",
                description: ' In the ever-evolving world of digital marketing, staying on the forefront of innovation is crucial. At LeadFactory, we strive to continuously innovate, experimenting with new strategies, technologies, and methodologies to provide our clients with superior lead generation services. We understand that the digital landscape is always changing, and we are flexible and adaptable, ready to pivot our strategies to meet these changes head-on.'
            }
        ]
    }

    const AchievementSectionProps = {
        achievements: [{
            title: 'Expanded Customer Base',
            description:
                'Successfully expanded our customer base by 200% in the last year.',
        },
        {
            title: 'Improved Client ROI',
            description:
                'Achieved an average ROI improvement of 30% for our clients.',
        },
        {
            title: 'Industry Recognition',
            description:
                "Awarded 'Best Lead Generation Company' at the annual industry awards.",
        },
        {
            title: 'Innovative Technology',
            description:
                'Developed proprietary lead scoring technology that increases lead quality by 40%.',
        },
        {
            title: 'Client Retention',
            description:
                'Maintained a 95% client retention rate over the past two years.',
        },
        {
            title: 'Growth and Expansion',
            description:
                'Opened new offices in 3 different countries, expanding our global reach.',
        },
        ],
    };


    return (
        <div>
            <Navbar {...navbarSectionProps} />
            <Title {...TitleSectionProps} />
            <People {...PeopleSectionProps} />
            <Achievements {...AchievementSectionProps} />
            <Values {...ValuesProps} />
            <FooterSection />
        </div>
    );
};

export default AboutUs;
