import React from 'react';
import './Title.scss';

interface TitleSectionProps {
    title: React.ReactNode;
    underTitle: string;
}

const HeroSection: React.FC<TitleSectionProps> = ({ title, underTitle }) => {
    return (
        <div className="hero-section">
            <div className="hero-content">
                <h1 className="hero-title">{title}</h1>
                <h3>{underTitle}</h3>
            </div>
        </div>
    );
};

export default HeroSection;
