import React from 'react';
import { Link } from 'react-router-dom';
import './FooterSection.scss';

function FooterSection() {
    return (
        <div className="footer-section">
            <p>© 2023 LeadFactory Corporation. All Rights Reserved.</p>
            <Link to="/privacy" className="privacy-link">Privacy policy</Link>
            <Link to="/kyc" className="privacy-link">Kyc policy</Link>
            <Link to="/cookies" className="privacy-link">Cookies</Link>
            <Link to="/refund" className="privacy-link">Refund policy</Link>
        </div>
    );
}

export default FooterSection;
