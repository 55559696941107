import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SocialIcons from './components/SocialIcons/SocialIcons';
import HomePage from './pages/HomePage/HomePage';
import Contact from './pages/Contact/Contact';
import AboutUs from './pages/AboutUs/AboutUs';
import Privacy from './pages/Privacy/Privacy';
import Cookies from './pages/Privacy/Cookies';
import KYC from './pages/Privacy/Kyc';
import Refund from './pages/Privacy/Refund';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import './App.scss';
library.add(fab);

function App() {
  return (
    <div className='App'>
      <Router>
        <SocialIcons />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/kyc" element={<KYC />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/refund" element={<Refund />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
