import React from 'react';
import './Testimonials.scss';

interface Testimonial {
    stars: number; // number of stars for the testimonial
    title: string; // title of the testimonial
    description: string; // description of the testimonial
}

interface TestimonialsProps {
    testimonials: Testimonial[];
}

const Testimonials: React.FC<TestimonialsProps> = ({ testimonials }) => {
    return (
        <div className='testimonials'>
            <h1>What our <span>customers</span> think about us?</h1>
            <div className='testimonial-container'>
                {testimonials.map((testimonial, index) => (
                    <div key={index} className='testimonial'>
                        <div className='testimonial-stars'>
                            {'★'.repeat(testimonial.stars)}
                        </div>
                        <div className='testimonial-title'>
                            {testimonial.title}
                        </div>
                        <div className='testimonial-description'>
                            {testimonial.description}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Testimonials;
