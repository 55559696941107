import React from 'react';
import HeroSection from '../../components/HeroSection/HeroSection';
import Navbar from '../../components/Navbar/Navbar';
import FooterSection from '../../components/FooterSection/FooterSection';
import FeatureSection from '../../components/Features/Features';
import ProductSection from '../../components/Products/Products';
import TestimonialsSection from '../../components/Testimonials/Testimonials';
import logo from '../../assets/logo.png';

const HomePage: React.FC = () => {
    const heroSectionProps = {
        underTitle: 'Take your business to the next level with us!',
    };
    const navbarSectionProps = {
        title: 'CryptoG',
        logo: logo,
        links: [
            { label: 'HOME', url: '/' },
            { label: 'ABOUT US', url: '/about' },
            { label: 'CONTACT', url: '/contact' }
        ]
    };

    const featuresSectionProps = {
        titles: ["Conversion optimization", "Lead strategy", "Lead audit"],
        texts: ["Optimize the conversion you get from each viewer", "Let's invent something great together", "Get a detailed lead report on your current tactics"]
    }


    const testimonialsSectionProps = {
        testimonials: [{
            title: "Best services in the industry",
            description: "I am really impressed with Lead Factory's services. They deliver what they promise, high-quality leads. My business has grown exponentially with their help. Can't thank them enough!",
            stars: 5
        },
        {
            title: "Leader of the market",
            description: "Lead Factory has been a major factor in our company's success. Their team's competence in lead generation has helped us reach our sales targets consistently. Truly one of the best decisions we've made!",
            stars: 5
        },
        {
            title: "Amazing achievements",
            description: "I am astounded by the results that Lead Factory has delivered. The increase in leads has been tremendous, and their quality is excellent. Their team is dedicated and really understands our needs. I highly recommend their services.",
            stars: 5
        },
        ]
    }

    const productsProps = {
        products: [
            { title: "AUTOFUNNELS", description: "The Autofunnel is an all-in-one marketing automation tool designed to streamline your sales process. It combines a range of features such as lead generation, email marketing, sales funnels, and analytics into a single platform. With Autofunnel, you can automate your marketing campaigns, nurture leads, and convert them into customers more efficiently." },
            { title: "COMMUNITY MANAGEMENT", description: "Our Community Management service helps you build and engage with your online community effectively. We assist in creating and managing online communities, such as social media groups, forums, or customer support channels. Our team ensures that your community members receive prompt responses, valuable content, and a positive experience, fostering brand loyalty and customer satisfaction." },
            { title: "INFLUENCER MARKETING", description: "Influencer Marketing is a powerful way to reach and engage with your target audience through influential individuals. We specialize in identifying relevant influencers in your industry, developing strategic partnerships, and executing impactful influencer marketing campaigns. Our goal is to amplify your brand's message, increase brand awareness, and drive authentic engagement with your target audience." },
            { title: "CONSUTING", description: "Our Consulting services provide expert guidance and strategic insights to help you optimize your marketing efforts. We work closely with your team to understand your business goals, analyze market trends, and develop customized strategies tailored to your unique needs. Our consultants provide actionable recommendations, industry best practices, and ongoing support to help you achieve sustainable growth." },
            { title: "PERFORMANCE", description: "Our Performance solutions focus on maximizing your digital marketing performance. We utilize data-driven approaches, advanced analytics, and optimization techniques to improve your online advertising campaigns, website conversions, and overall marketing ROI. By continuously monitoring and optimizing your digital channels, we help you achieve better results and drive measurable business outcomes." },
            { title: "STRATEGY", description: "Our Strategy services help you develop a comprehensive marketing strategy that aligns with your business objectives. We conduct market research, competitive analysis, and audience segmentation to identify growth opportunities and define a clear roadmap. With a well-defined strategy in place, we empower your business to make informed decisions, effectively allocate resources, and achieve long-term success in the dynamic digital landscape." }
        ]
    };

    return (
        <div className="home-page">
            <Navbar {...navbarSectionProps} />
            <HeroSection {...heroSectionProps} />
            <FeatureSection {...featuresSectionProps} />
            <ProductSection {...productsProps} />
            <TestimonialsSection {...testimonialsSectionProps} />
            <FooterSection />
        </div>
    );
};

export default HomePage;
