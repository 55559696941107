import React from 'react';
import './SocialIcons.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTiktok, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
library.add(faFacebookF, faTiktok, faInstagram, faTwitter);

const SocialIcons: React.FC = () => {
    return (
        <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=100092667704474" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://www.instagram.com/leadfactoryagency/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://twitter.com/LeadFactory_" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTwitter} />
            </a>
        </div>
    );
};

export default SocialIcons;
