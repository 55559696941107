import React, { useState, useEffect } from 'react';
import './Achievements.scss';
import { motion, AnimatePresence } from 'framer-motion';

interface Achievement {
    title: string;
    description: string;
}

interface AchievementsProps {
    achievements: Achievement[];
}

const Achievements: React.FC<AchievementsProps> = ({ achievements }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(window.innerWidth <= 767 ? 1 : 3);

    useEffect(() => {
        const handleResize = () => {
            setItemsToShow(window.innerWidth <= 767 ? 1 : 3);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + achievements.length) % achievements.length);
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % achievements.length);
    };

    const isActive = (index: number) => {
        return index >= currentIndex && index < currentIndex + itemsToShow;
    };

    return (
        <div className='achievementSection'>
            <h1>Our greatest <span>achievements</span></h1>
            <div className="achievements-container">
                <button className="arrow left-arrow" onClick={handlePrevClick}>
                    &larr;
                </button>
                <div className="achievements">
                    {achievements.map((achievement, index) => (
                        <AnimatePresence>
                            {isActive(index) && (
                                <motion.div
                                    key={index}
                                    className={`achievement ${isActive(index) ? 'active' : ''}`}
                                    initial={{ x: 100, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    exit={{ x: -100, opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <div className="white-container">
                                        <h1>{achievement.title}</h1>
                                        <div className="description">
                                            <p>{achievement.description}</p>
                                        </div>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    ))}

                </div>
                <button className="arrow right-arrow" onClick={handleNextClick}>
                    &rarr;
                </button>
            </div>
        </div>
    );
};

export default Achievements;
