import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears, faMap, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import './Features.scss';

interface FeaturesProps {
    titles: string[];
    texts: string[];
}

const Features: React.FC<FeaturesProps> = ({ titles, texts }) => {
    return (
        <div className='features'>
            <div className='single-feature'>
                <div className='icon'>
                    <FontAwesomeIcon icon={faGears} className='feature-icon' />
                </div>
                <h3>{titles[0]}</h3>
                <p>{texts[0]}</p>
            </div>
            <div className='single-feature'>
                <div className='icon'>
                    <FontAwesomeIcon icon={faMap} className='feature-icon' />
                </div>
                <h3>{titles[1]}</h3>
                <p>{texts[1]}</p>
            </div>
            <div className='single-feature'>
                <div className='icon'>
                    <FontAwesomeIcon icon={faCheckSquare} className='feature-icon' />
                </div>
                <h3>{titles[2]}</h3>
                <p>{texts[2]}</p>
            </div>
        </div>
    );
};

export default Features;
