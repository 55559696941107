import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Title from '../../components/Title/Title';
import Email from '../../components/Email/Email';
import Faq from '../../components/Faq/Faq';
import FooterSection from '../../components/FooterSection/FooterSection';
import logo from '../../assets/logo.png';


const Contact: React.FC = () => {
    const navbarSectionProps = {
        title: 'CryptoG',
        logo: logo,
        links: [
            { label: 'HOME', url: '/' },
            { label: 'ABOUT US', url: '/about' },
            { label: 'CONTACT', url: '/contact' }
        ]
    };

    const TitleSectionProps = {
        title: <>Contact us <span>whenever</span> you want!</>,
        underTitle: 'Our team is always happy to anwser you questions!'
    };

    const QuestionsProps = {
        questions: [
            { question: 'Campaign creation and management', anwser: 'The agency takes full control over campaign planning and monitoring.' },
            { question: 'Influencer selection', anwser: "Based on the brand's requirements, LeadFactory finds top-performing influencers on different social media platforms, negotiates the rates, and creates a legal agreement for this partnership." },
            { question: 'Campaign optimization', anwser: 'The agency runs several tests on different social media categories until the most suitable influencers are found.' },
            { question: 'Campaign scaling', anwser: 'Once the selection and shortlisting are done, LeadFactory scales the campaign by expanding the pool of top-performing influencers.' },
            { question: 'Campaign analysis & reporting', anwser: 'After every campaign, LeadFactory analyzes the results.' },
        ]
    }

    return (
        <div>
            <Navbar {...navbarSectionProps} />
            <Title {...TitleSectionProps} />
            <Email />
            <Faq {...QuestionsProps} />
            <FooterSection />
        </div>
    )
}

export default Contact;