import React from 'react'
import Navbar from '../../components/Navbar/Navbar';
import FooterSection from '../../components/FooterSection/FooterSection';
import NotFound from '../../components/NotFoundPage/NotFound';
import logo from '../../assets/logo.png';

const NotFoundPage: React.FC = () => {
    const navbarSectionProps = {
        title: 'CryptoG',
        logo: logo,
        links: [
            { label: 'HOME', url: '/' },
            { label: 'ABOUT US', url: '/about' },
            { label: 'CONTACT', url: '/contact' }
        ]
    };

    return (
        <div>
            <Navbar {...navbarSectionProps} />
            <NotFound />
            <FooterSection />
        </div>
    )
};

export default NotFoundPage;