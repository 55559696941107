import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/FooterSection/FooterSection';
import Refun from '../../components/refund/refund';
import logo from '../../assets/logo.png';

const Refund: React.FC = () => {
    const navbarSectionProps = {
        title: 'CryptoG',
        logo: logo,
        links: [
            { label: 'HOME', url: '/' },
            { label: 'ABOUT US', url: '/about' },
            { label: 'CONTACT', url: '/contact' }
        ]
    };




    return (
        <div>
            <Navbar {...navbarSectionProps} />
            <Refun />
            <Footer />
        </div>
    );
}

export default Refund;