import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/FooterSection/FooterSection';
import Policy from '../../components/Policy/Policy';
import logo from '../../assets/logo.png';

const Privacy: React.FC = () => {
    const navbarSectionProps = {
        title: 'CryptoG',
        logo: logo,
        links: [
            { label: 'HOME', url: '/' },
            { label: 'ABOUT US', url: '/about' },
            { label: 'CONTACT', url: '/contact' }
        ]
    };




    return (
        <div>
            <Navbar {...navbarSectionProps} />
            <Policy />
            <Footer />
        </div>
    );
}

export default Privacy;