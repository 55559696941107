import React from 'react';
import './Products.scss';

interface ProductProps {
    title: string;
    description: string;
}

interface ProductsProps {
    products: ProductProps[];
}

const Products: React.FC<ProductsProps> = ({ products }) => {
    return (
        <div className="products-container">
            {products.map((product, index) => (
                <div className="product" key={index}>
                    <div className="card">
                        <div className="front">{product.title}</div>
                        <div className="back"><p>{product.description}</p></div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Products;
