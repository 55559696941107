import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './Navbar.scss';

interface NavbarProps {
    logo: string;
    links: { label: string, url: string }[];
}

const Navbar: React.FC<NavbarProps> = ({ logo, links }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className={`navbar ${menuOpen ? 'open' : ''}`}>
            <div className="logo-container">
                <Link to='/'><img src={logo} alt='logo' /></Link>
            </div>
            <div className='bar'>
                <ul className={`navbar-nav ${menuOpen ? 'open' : ''}`}>
                    {links.map((link, index) => (
                        <li key={index} className={`nav-item ${location.pathname === link.url ? 'active' : ''}`}>
                            <Link to={link.url}>{link.label}</Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="menu-toggle-container">
                <button className="menu-toggle" onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
        </nav>
    );
};

export default Navbar;
