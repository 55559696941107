import React from 'react';
import './Kyc.scss';

function Kyc() {
    return (
        <div className="privacy-container">
            <h1>KYC Policy</h1>
            <h2>Introduction</h2>
            <p>
                Lead Factory Know Your Customer Policy (hereinafter – the "KYC Policy") is designated to prevent and mitigate possible risks of Lead Factory being involved in any kind of illegal activity. Both international and local regulations require Lead Factory to implement effective internal procedures and mechanisms to prevent money laundering, terrorist financing, drug and human trafficking, proliferation of weapons of mass destruction, corruption and bribery, and to take action in case of any form of suspicious activity from its Users.
            </p>

            <h2>KYC Policy covers the following matters:</h2>
            <ul>
                <li>Verification procedures</li>
                <li>Compliance Officer</li>
            </ul>

            <h2>1. Definitions and Interpretation</h2>
            <p>
                In this Know Your Client (KYC) document, the following words shall have the meaning respectively ascribed to them:
            </p>
            <p><strong>«KYC»</strong> means the Know Your Client policy.</p>
            <p><strong>«Company»</strong> shall refer to Lead Factory.</p>

            <h2>2. What is KYC</h2>
            <p>
                KYC is an acronym for "Know your Client" and used for client identification process. The objective of the KYC guidelines is to prevent the Company from being used, intentionally or unintentionally, by criminal elements for money laundering.
            </p>

            <h2>3. Verification procedures</h2>
            <p>
                One of the international standards for preventing illegal activity is customer due diligence ("CDD"). According to CDD, Lead Factory establishes its own verification procedures within the standards of "Know Your Customer" framework.
            </p>
            <p>
                When registering on the platform, each blogger indicates his/her surname and name, registration address, e-mail address, phone, and a link to his/her social media profile, card number/account number/paypal email/wallet number, bank details, card holder name, card expire.
            </p>
            <p>
                After verifying the blogger's data, Compliance Officer may request copies/photos of the following documents:
            </p>
            <ul>
                <li>National Identity Card or Passport</li>
                <li>Recent Utility Bill or Bank statement no older than six (6) months (for the verification of the address)</li>
                <li>Copies of his/her credit card</li>
            </ul>

            <h2>4. Compliance Officer</h2>
            <p>
                The Compliance Officer is the person, duly authorized by Lead Factory, whose duty is to ensure the effective implementation and enforcement of the KYC Policy. It is the Compliance Officer's responsibility to supervise all aspects of Lead Factory's anti-money laundering and counter-terrorist financing, including but not limited to:
            </p>
            <ul>
                <li>Collecting Users' identification information</li>
                <li>Establishing and updating internal policies and procedures for the completion, review, submission, and retention of all reports and records required under the applicable laws and regulations</li>
                <li>Providing law enforcement with information as required under the applicable laws and regulations</li>
            </ul>
            <p>
                The Compliance Officer is entitled to interact with law enforcement, which are involved in the prevention of money laundering, terrorist financing, and other illegal activity.
            </p>
            <h2>5. Objectives of the KYC policy</h2>
            <p>
                The objectives of the KYC policy are as follows:
            </p>
            <ul>
                <li>To prevent criminal elements from using the Company for money laundering activities</li>
                <li>To implement sufficient controls for the detection and reporting of suspicious and/or potentially illegal activities in accordance with the applicable laws and procedures</li>
            </ul>

            <h2>6. Importance to comply with KYC procedures</h2>
            <p>
                KYC procedures are part of the anti-money laundering laws and regulations, which aim to prevent the practice of generating income through illegal and criminal actions.
            </p>

            <h2>7. How to send your documents</h2>
            <p>
                Please scan your documents or take a high-quality digital camera picture, save the images as JPEGs, then send them to us via mail to Support@flagency.com.
            </p>

            <h2>8. Privacy</h2>
            <p>
                Lead Factory is committed to protecting your privacy. All the information that you share with us is confidential. We only collect personal information from clients in order to comply with the prevalent Laws and Regulations. We do not share your information with any outside parties.
            </p>
        </div>
    );
}

export default Kyc;

