import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import this
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import './Faq.scss';

interface Questions {
    question: string;
    anwser: string;
}

interface QuestionsProps {
    questions: Questions[];
}

const Faq: React.FC<QuestionsProps> = ({ questions }) => {
    const [expandedQuestion, setExpandedQuestion] = useState<number | null>(null);

    const toggleQuestion = (index: number) => {
        setExpandedQuestion(expandedQuestion === index ? null : index);
    };

    return (
        <div className="faq-container">
            <h1>FAQ</h1>
            {questions.map((item, index) => (
                <div key={index} className="faq-item">
                    <div className="faq-question" onClick={() => toggleQuestion(index)}>
                        <span>{item.question}</span>
                        <span className="arrow">
                            <FontAwesomeIcon icon={expandedQuestion === index ? faAngleUp : faAngleDown} />
                        </span>
                    </div>
                    <AnimatePresence>
                        {expandedQuestion === index && (
                            <motion.div
                                className="faq-answer"
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <p>{item.anwser}</p>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <div className="faq-divider"></div>
                </div>
            ))}
        </div>
    );
};

export default Faq;
