import React from 'react';
import './Cookies.scss';

function Cookies() {
    return (
        <div className="privacy-container">
            <h1>Cookies Policy</h1>

            <h2>1. Introduction</h2>
            <ol>
                <li>Our website uses cookies of third parties.</li>
                <li>
                    Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.
                </li>
            </ol>

            <h2>2. About cookies</h2>
            <ol>
                <li>
                    A cookie is a file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.
                </li>
                <li>
                    Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.
                </li>
                <li>
                    Cookies do not typically contain any information that personally identifies a user, but personal information that we store and about you may be linked to the information stored in and obtained from cookies.
                </li>
                <li>
                    Service providers use cookies and those cookies may be stored on your computer when you visit our website.
                </li>
                <li>
                    We use cookies of the following service providers, and the cookies are used to collect information about how visitors use our site. We use the information to compile reports and to help us improve our site. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the site from, and the pages they visited.
                    <ul>
                        <li>
                            Amplitude.com
                            <ul>
                                <li>Privacy policy: <a href="https://amplitude.com/privacy" target="_blank" rel="noopener noreferrer">https://amplitude.com/privacy</a></li>
                                <li>You may opt out of tracking by sending a message to opt out on privacy@amplitude.com</li>
                            </ul>
                        </li>
                        <li>
                            Google Analytics
                            <ul>
                                <li>Privacy policy: <a href="http://www.google.com/analytics/learn/privacy.html" target="_blank" rel="noopener noreferrer">http://www.google.com/analytics/learn/privacy.html</a></li>
                                <li>You may opt out of tracking by visiting <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=en-GB</a></li>
                            </ul>
                        </li>
                        <li>
                            Metrika.yandex.ru
                            <ul>
                                <li>Privacy policy: <a href="https://yandex.com/support/metrica/general/gdpr.html" target="_blank" rel="noopener noreferrer">https://yandex.com/support/metrica/general/gdpr.html</a></li>
                                <li>You may opt out of tracking by contacting the owner via <a href="https://yandex.com/support/metrica/general/opt-out.html"
                                    target="_blank" rel="noopener noreferrer">https://yandex.com/support/metrica/general/opt-out.html</a></li>
                            </ul>
                        </li>
                        <li>
                            Intercom.io
                            <ul>
                                <li>Privacy policy: <a href="https://www.intercom.com/terms-and-policies#privacy" target="_blank" rel="noopener noreferrer">https://www.intercom.com/terms-and-policies#privacy</a></li>
                                <li>You may opt out of tracking by sending a message to opt out in compliance@intercom.com</li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ol>
            <h2>3. Managing cookies</h2>
            <ol>
                <li>
                    Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can, however, obtain up-to-date information about blocking and deleting cookies via these links:
                    <ul>
                        <li>Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noopener noreferrer">https://support.google.com/chrome/answer/95647?hl=en</a></li>
                        <li>Firefox: <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noopener noreferrer">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a></li>
                        <li>Opera: <a href="http://www.opera.com/help/tutorials/security/cookies/" target="_blank" rel="noopener noreferrer">http://www.opera.com/help/tutorials/security/cookies/</a></li>
                        <li>Internet Explorer: <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a></li>
                        <li>Safari: <a href="https://support.apple.com/kb/PH21411" target="_blank" rel="noopener noreferrer">https://support.apple.com/kb/PH21411</a></li>
                        <li>Edge: <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy" target="_blank" rel="noopener noreferrer">https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</a></li>
                    </ul>
                </li>
                <li>Blocking all cookies will have a negative impact upon the usability of many websites.</li>
                <li>If you block cookies, you will not be able to use all the features on our website.</li>
            </ol>

            <h2>4. Our details</h2>
            <p>Lead Factory LLC 30 N Gould St Ste R, Sheridan, WY 82801</p>
            <p>Support@flagency.com</p>
        </div>
    );
}

export default Cookies;