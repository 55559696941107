import React from 'react';
import './Policy.scss';

function Policy() {
    return (
        <div className="privacy-container">
            <h1>Privacy policy</h1>
            <p>
                This Privacy Policy explains how LeadFactory ("we" or "us") collects, uses, and protects the personal information you provide when accessing or using our website.
            </p>
            <p>
                <strong>Information We Collect</strong>
            </p>
            <p>
                1.1 Personal Information: We may collect personal information, such as your name, email address, phone number, and any other information you voluntarily provide to us when you fill out forms, sign up for newsletters, request information, or interact with our website.
            </p>
            <p>
                1.2 Log Data: When you visit our website, our servers automatically record certain log data, including your IP address, browser type, operating system, referring URLs, and other anonymous statistical data.
            </p>
            <p>
                <strong>Use of Information</strong>
            </p>
            <p>
                2.1 Provide Services: We may use the information we collect to respond to your inquiries, provide the services you request, and communicate with you about your account or any updates or changes to our services.
            </p>
            <p>
                2.2 Marketing Communication: With your consent, we may send you promotional emails or newsletters about our products, services, or industry-related information that we think may be of interest to you. You have the option to unsubscribe from these communications at any time.
            </p>
            <p>
                2.3 Analytics and Improvements: We may use the information we collect for analytical purposes, such as analyzing trends, administering the website, improving our products and services, and enhancing the overall user experience.
            </p>
            <p>
                <strong>Data Sharing and Disclosure</strong>
            </p>
            <p>
                3.1 Service Providers: We may share your personal information with trusted third-party service providers who assist us in operating our website, conducting our business, and providing services to you. These service providers are obligated to protect your information and may only use it for the specific purposes we have contracted them for.
            </p>
            <p>
                3.2 Legal Requirements: We may disclose your personal information if required to do so by law or if we believe that such action is necessary to comply with a legal obligation, protect and defend our rights and property, prevent fraud, or enforce our Terms and Conditions.
            </p>
            <p>
                <strong>Data Security</strong>
            </p>
            <p>
                We take appropriate technical and organizational measures to safeguard the personal information we collect from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.
            </p>
            <p>
                <strong>Your Rights</strong>
            </p>
            <p>
                You have the right to access, update, or delete your personal information. If you would like to exercise any of these rights or have any questions or concerns about our privacy practices, please contact us using the information provided below.
            </p>
            <p>
                <strong>Changes to this Privacy Policy</strong>
            </p>
            <p>
                We may update this Privacy Policy from time to time. Any changes we make will be posted on this page, and the revised policy will become effective immediately upon posting. We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your information.
            </p>
            <p>
                <strong>Contact Us</strong>
            </p>
            <p>
                If you have any questions or concerns about this Privacy Policy, please contact us at:
            </p>
            <p>
                Lead Factory LLC<br />
                30 N Gould St Ste R, Sheridan,<br />
                WY 82801<br />
                Email: Support@flagency.com<br />
                Certified 17-28-101 W.S. 17-28-111 <br />
                Phone: (307) 200-2803
            </p>
        </div>
    );
}

export default Policy;

