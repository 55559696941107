// Values.tsx
import React from 'react';
import './Values.scss';

interface Value {
    title: string;
    description: string;
}

interface ValuesProps {
    values: Value[];
}

const Values: React.FC<ValuesProps> = ({ values }) => {
    return (
        <div className="values-section">
            <h1>Our main <span>values</span></h1>
            <div className="values-grid">
                {values.map((value, index) => (
                    <div className={`value-pair ${index % 2 === 0 ? 'row' : 'row-reverse'}`} key={index}>
                        <div className="value-image">
                            <h1>{value.title}</h1>
                        </div>
                        <div className="value-description">
                            <p>{value.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Values;
