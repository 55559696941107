import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Email.scss';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Email() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageStatus, setMessageStatus] = useState('');

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setMessageStatus('Submitting...'); // Set status to 'Submitting...' when form is submitted

        let service = process.env.REACT_APP_SERVICE || '';
        let template = process.env.REACT_APP_TEMPLATE || '';
        let userId = process.env.REACT_APP_USER_ID || '';

        emailjs.send(
            service,
            template,
            { name, email, message },
            userId
        )
            .then((result) => {
                console.log(result.text);
                setMessageStatus('Email sent successfully!');
            }, (error) => {
                console.log(error.text);
                setMessageStatus('An error occurred. Please try again.');
            });

        // Reset form fields
        setName('');
        setEmail('');
        setMessage('');
    };

    const variants = {
        hidden: { opacity: 0, y: 50 },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                ease: 'easeOut'
            }
        }
    };

    return (
        <div className="email-form-container" ref={ref}>
            <motion.form
                onSubmit={handleSubmit}
                variants={variants}
                initial='hidden'
                animate={inView ? 'show' : 'hidden'}
            >
                <div className="input-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        required
                    ></textarea>
                </div>
                <button type="submit">Send Message</button>
            </motion.form>
            <div className="message-status">{messageStatus}</div>
        </div>
    );
}

export default Email;
